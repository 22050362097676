import { CustomTheme } from "../theme";

export const serotoninaTheme: CustomTheme = {
  colors: {
    primary: "#F99429",
    secondary: "#404041",
    background: "rgb(241, 242, 243)",
    text: "#000",
    borders: "#edf3f5",
    title: "#000",
    navLink: "#15418e",
    labels: "#404041",
    error: "#ea4a40",
  },
  links: {
    home: "/serotonina/app",
    logo: "/logos/serotonina2.png",
    supportEmail: "info@curtisapps.com",
    googlePlay:
      "https://play.google.com/store/apps/details?id=com.curtisapps.serotonina",
    appStore: "",
  },
  title: "Serotonina",
};
